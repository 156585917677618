import { createApp } from 'vue'
import {
    Button, Layout, Dropdown,
    Input, Image, Switch, Tabs,
    Row, Col, Menu, Radio, Tooltip,
    Table, Modal, Form, Tree, TreeSelect, Pagination,
    Checkbox, Select, Avatar, Upload, Spin, List,
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router/index'
import { createPinia } from 'pinia'



const app = createApp(App)

// app.config.globalProperties.$baseHost = location.origin + '/rc'
app.use(Button)
app.use(Layout)
app.use(List)
app.use(Dropdown)
app.use(Input)
app.use(Image)
app.use(Row)
app.use(Col)
app.use(Menu)
app.use(Radio)
app.use(Tooltip)
app.use(Switch)
app.use(Table)
app.use(Modal)
app.use(Form)
app.use(Tree)
app.use(Tabs)
app.use(TreeSelect)
app.use(Pagination)
app.use(Checkbox)
app.use(Select)
app.use(Avatar)
app.use(Upload)
app.use(Spin)

app.use(router)
// 挂载vuex状态管理
app.use(createPinia())
app.mount('#app')
