<template>
  <div>
    <ConfigProvider :locale="zhCN">
      <router-view v-slot="{ Component }">
        <!-- <Suspense>  :key="route.path"-->
        <component :is="Component" />
        <!-- </Suspense> -->
      </router-view>
    </ConfigProvider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { ConfigProvider } from 'ant-design-vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    ConfigProvider
  },
  setup() {
    const route = useRoute()
    return {
      route,
      zhCN
    }
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
.hidden {
  visibility: hidden;
}

.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #f44336;
}
.my-drop-menu {
  .ant-dropdown-menu-item {
    min-width: 132px;
    color: #666;
    text-align: center;
    padding: 10px 12px;
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
    .icon {
      width: 24px;
      height: 24px;
      margin-top: -4px;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .ant-dropdown-menu-item.active {
    color: #333;
    font-weight: bold;
  }
}
.userModal {
  .ant-form-item-control .ant-form-item-control-input-content {
    display: inline-flex;
    align-items: center;
  }
}
.ant-modal {
  .ant-modal-title {
    text-align: center;
  }
  .ant-form {
    width: 420px;
    margin: 0 auto;
    .ant-form-item-label > label {
      font-size: 16px;
    }
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }

    .icon-r {
      width: 24px;
      cursor: pointer;
    }
  }
}
.deptModal {
  .radio-types {
    display: block;
    text-align: center;
    padding: 10px 0 30px;
    .tab-radios {
      display: inline-flex;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      .tab {
        display: inline-block;
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color: #333;
        font-size: 14px;
        cursor: pointer;
      }

      .tab:first-child {
        border-right: 1px solid #d9d9d9;
      }
      .tab.active {
        color: #fff;
        background-color: #3e6cec;
      }
    }
  }
}
.main-box {
  width: 100%;
  min-height: 85vh;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  .head-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-box {
      display: inline-flex;
      align-items: center;
      > span {
        margin-right: 10px;
      }
      .name {
        display: inline-block;
        min-width: 120px;
      }
    }
    .icon-main {
      color: #3e6cec;
      cursor: pointer;
    }
  }
  .btn-red {
    color: #f44336;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #3e6cec;
  border-color: #3e6cec;
}
.ant-radio-inner::after {
  background-color: #3e6cec;
}
.ant-radio-checked::after {
  border-color: #3e6cec;
}
.ant-btn-primary {
  background: #3e6cec;
  border-color: #3e6cec;
  border-radius: 4px;
}
.ant-btn-link {
  color: #3e6cec;
}
.ant-btn-primary:focus {
  background: #3e6cec;
  border-color: #3e6cec;
}
.ant-btn-primary:active {
  background: #3e6cec;
  border-color: #3e6cec;
}
:deep(.ant-form) {
  width: 460px;
  .ant-form-item-label > label {
    font-size: 16px;
    margin-right: 20px;
  }
  .ant-form-item {
    .ant-form-text {
      font-size: 16px;
      color: #333;
    }
    .ant-input {
      font-size: 16px;
      border-radius: 4px;
    }
  }
  .btn-tools {
    margin-top: 32px;
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
      .ant-btn {
        border-radius: 4px;
      }
    }
  }
}
.gray {
  color: #999;
}
/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* router view transition */
.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: transform 0.35s, opacity 0.28s ease-in-out;
}

.zoom-fade-enter-from {
  opacity: 0;
  transform: scale(0.97);
}

.zoom-fade-leave-to {
  opacity: 0;
  transform: scale(1.03);
}
.w-e-text-container * {
  width: 100%;
}
.w-e-toolbar {
  z-index: 2 !important; // 这是工具栏
}
.w-e-text-container {
  z-index: 1 !important; // 这是工具栏
}
/*滚动条整体粗细样式*/
::-webkit-scrollbar {
  /*高宽分别对应横竖滚动条的尺寸*/
  width: 8px;
  height: 8px;
}
.desc video,
.rich-text video,
.desc img,
.rich-text img {
  max-width: 100%;
}
/*滚动条里面小方块*/
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  /* 颜色 */
  background: #ececec !important;
  /* 线性渐变背景 */
  // background-image: linear-gradient(
  //   45deg,
  //   #ffbd61 25%,
  //   #ffbd61 25%,
  //   #ff8800 25%,
  //   #ff8800 50%,
  //   #ffbd61 50%,
  //   #ffbd61 75%,
  //   #ff8800 75%,
  //   #ff8800 100%
  // ) !important;
}

/*滚动条轨道*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
  background: #f9f9f9 !important;
}
img {
  object-fit: cover;
}
.gutter-box {
  .xgplayer-skin-default .xgplayer-poster {
    background-size: contain;
    background-position: center;
  }
}
</style>
