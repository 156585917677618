<template>
  <div ref="mainBox" class="main-box">
    <a-row :gutter="24" class="row-box">
      <a-col class="gutter-row" :span="18">
        <div class="top-head">
          <span class="title"
            >资源库 <RightOutlined /> {{ fileObj.rcName }}
            <img
              v-if="fileObj?.createUser"
              class="icon-tag"
              src="../../../assets/images/icon_tag_on.png"
              alt="icon-tag"
              @click="showTopicModal"
            />
          </span>
          <span v-show="fileObj.uploadTime"
            >{{ fileObj.uploadUserNickName }}上传于:{{
              fileObj.uploadTime
            }}</span
          >
        </div>
        <div class="gutter-box">
          <div class="student-list text-box">
            <div class="title">{{ fileObj.fileName }}</div>
            <div id="richBox" ref="richRef" class="rich-text">
              <div v-html="fileObj.fileHtml"></div>
            </div>
            <!-- -->
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <div class="top-head top-count">
          <div class="icon-item" @click="onCollectFile(1)">
            <img
              v-if="fileObj.likeStatus == 1"
              class="icon-mini"
              src="../../../assets/client/icon_like_on.png"
              alt=""
            />
            <img
              v-else
              class="icon-mini"
              src="../../../assets/client/icon_like.png"
              alt=""
            />

            点赞
          </div>
          <div class="icon-item" @click="onCollectFile(2)">
            <img
              v-if="fileObj.collectStatus == 1"
              class="icon-mini"
              src="../../../assets/client/icon_star_on.png"
              alt=""
            />
            <img
              v-else
              class="icon-mini"
              src="../../../assets/client/icon_star.png"
              alt=""
            />
            收藏
          </div>
          <div
            class="icon-item"
            @click="changeRecord"
            v-if="fileObj.recordFlag == 1"
          >
            <img
              v-show="activeKey == '1'"
              class="icon-mini"
              src="../../../assets/client/icon_look.png"
            />
            <img
              v-show="activeKey == '2'"
              class="icon-mini"
              src="../../../assets/client/icon_look_on.png"
            />
            浏览记录
          </div>
          <div
            class="icon-item"
            @click="delResFile"
            v-if="fileObj.uploadUserId === user.userId"
          >
            <img
              class="icon-mini"
              src="../../../assets/client/delete_light.png"
            />
            删除
          </div>
        </div>
        <div class="gutter-box">
          <div class="all-account">
            <div class="student-list">
              <a-list v-show="activeKey == '1'">
                <a-list-item v-for="comment in commentList" :key="comment.id">
                  <div class="rating-item">
                    <div class="rating-text">
                      {{ comment.content }}
                    </div>
                    <div class="user-box">
                      <div class="user-info">
                        <img
                          class="icon-avatar"
                          :src="baseHost + comment.avatar"
                          alt="avatar"
                        />
                        <span>{{ comment.nickName }}</span>
                      </div>
                      <span class="icon-plus">
                        {{ moment(comment.createTime).format('MM-DD HH:mm') }}
                      </span>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
              <a-list class="recordList" v-show="activeKey == '2'">
                <a-list-item
                  v-for="recordItem in recordList"
                  :key="recordItem.id"
                >
                  <div class="rating-item">
                    <div
                      class="user-box"
                      style="justify-content: space-between"
                    >
                      <div class="user-info">
                        <img
                          class="icon-avatar"
                          :src="baseHost + recordItem.avatar"
                          alt="avatar"
                        />
                        <span>{{ recordItem.nickName }}</span>
                      </div>
                      <span class="icon-plus">
                        浏览于
                        {{
                          moment(recordItem.visitTime).format('MM-DD HH:mm:ss')
                        }}
                      </span>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
              <div
                class="pages"
                v-if="commentTotal > 5 || recordList.length > 5"
              >
                <a-pagination
                  v-model:current="curPage"
                  simple
                  :pageSize="pageSize"
                  :total="commentTotal"
                >
                  <template #itemRender="{ page, type, originalElement }">
                    <a-button
                      size="small"
                      v-if="type === 'prev'"
                      @click="nextPage(page)"
                      >上一页</a-button
                    >
                    <a-button
                      size="small"
                      v-else-if="type === 'next'"
                      @click="nextPage(page)"
                      >下一页</a-button
                    >
                    <component :is="originalElement" v-else></component>
                  </template>
                </a-pagination>
              </div>
              <div class="post-text" v-show="activeKey == '1'">
                <a-textarea
                  row="5"
                  maxLength="200"
                  v-model:value="commentText"
                  placeholder="请输入你的想法...."
                ></a-textarea>
                <a-button type="primary" size="small" @click="sendText"
                  >发表</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      dialogClass="topicModal"
      :maskClosable="false"
      :getContainer="mainBox"
      v-model:visible="showTopic"
      centered
      :closable="false"
      width="45%"
      title="关联话题"
      okText="关联"
      @cancel="hideTopicModal"
      @ok="saveTopic"
    >
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <a-input-search
            placeholder="搜索标签名称"
            size="default"
            allowClear
            v-model:value="tagName"
            @search="onSearchTags"
          >
          </a-input-search>
        </a-col>
        <a-col :span="24">
          <div class="head">热门话题</div>
          <div class="hot-tag-list">
            <span
              class="tag"
              v-for="tag in hotTags"
              :key="tag.id"
              @click="addTag(tag)"
              >{{ tag.tagName }}</span
            >
          </div>
        </a-col>
        <a-col :span="24" v-if="vdTags.length > 0">
          <div class="head">已选择</div>
          <div class="hot-tag-list">
            <span
              class="tag active"
              v-for="(tag, index) in vdTags"
              :key="tag.id"
              >{{ tag.tagName }}
              <CloseOutlined class="i-close" @click="removeTag(index)" />
            </span>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  toRefs,
  reactive,
  onMounted,
  onUnmounted
} from 'vue'
import {
  SearchOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  FormOutlined,
  RightOutlined,
  CloseOutlined
} from '@ant-design/icons-vue'
import {
  queryFileDetail,
  commentFileList,
  collectLikes,
  postComment,
  recordVisit,
  queryCollectStatus,
  queryRecord,
  editFileDetail,
  deleteFile
} from '../../../api/userApi'
import { getVideoTags } from '../../../api/adminApi'
import vdWait from '../../../assets/images/vd_wait.png'
import vdFail from '../../../assets/images/vd_file.png'
import Player from 'xgplayer'
import { useRouter } from 'vue-router'
import { encode } from 'js-base64'
import { useRoute } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import _ from 'lodash'
// const docx = require('docx-preview')
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf'
const moment = require('moment')
export default defineComponent({
  components: {
    SearchOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    FormOutlined,
    RightOutlined,
    CloseOutlined,
    DeleteOutlined
  },

  setup() {
    PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/legacy/build/pdf.worker.entry.js')
    const route = useRoute()
    const id = route.query.id
    const businessType = 3 // 文件类型
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    let vdPlayer = null
    const router = useRouter()
    const mainBox = ref()
    const richRef = ref()
    const state = reactive({
      curPage: 1,
      commentTotal: 0,
      activeKey: '1',
      pageSize: 5,
      gData: [], // 组织架构
      commentList: [], // 消息列表
      recordList: [], // 浏览记录
      fileObj: { id: '', uploadUserId: '' }, // 文件详情
      pagination: false,
      commentText: '', //评论内容
      baseHost: location.origin + '/rc',
      tagName: '',
      hotTags: [], // 热门话题
      vdTags: [], // 已选tag
      showTopic: false,
      playerTime: ''
    })

    const queryCollect = async () => {
      const collectRes = await queryCollectStatus({
        businessType: businessType,
        businessId: id
      })
      if (collectRes.code == 200) {
        state.fileObj.likeStatus = collectRes.data.likeStatus
        state.fileObj.collectStatus = collectRes.data.collectStatus
      }
    }
    const queryCOmment = async (page = 1) => {
      const commentRes = await commentFileList({
        businessType: businessType,
        businessId: id,
        pageNum: page || 1,
        pageSize: state.pageSize
      })
      if (commentRes.code == 200) {
        state.commentList = commentRes.rows
        state.commentTotal = commentRes.total
      }
    }

    // 查询浏览记录
    const queryRecordData = async (page) => {
      const visRes = await queryRecord({
        // 查询文件浏览记录
        businessType: businessType,
        businessId: id,
        pageNum: page || 1,
        pageSize: state.pageSize
      })
      if (visRes.code == 200) {
        state.recordList = visRes.rows
      }
    }

    // 查询标签话题
    const queryTopic = async () => {
      const res = await getVideoTags({ pageNum: 1, pageSize: 20, orderType: 3 })
      if (res.code == 200) {
        state.hotTags = res.rows
      }
    }

    // 编辑文件详情
    const eidtFileTags = async (tagIds) => {
      const resObj = await editFileDetail({
        id: state.fileObj.id,
        tagIds: tagIds
      })
      if (resObj.code == 200) {
        message.success('关联话题已更新').then(() => {
          state.fileObj.tagsBusResList = state.vdTags
        })
      }
    }

    onMounted(() => {
      // 文件详情
      queryFileDetail(id)
        .then((res) => {
          if (res.code === 200) {
            // 1-音频 2-视频 3-图片 4-文本  5-文档 6 - 压缩包
            let fileHtml = null
            switch (res.data.fileType) {
              case 1:
                fileHtml = `<audio controls controlslist="nodownload" style='margin-top:50px;max-width:100%;width:80%;'> <source src='${res.data.fileUrl}' type="audio/mpeg"></audio>`
                break
              case 2:
                if (res.data.previewStatus == 1) {
                  vdPlayer = new Player({
                    id: 'richBox',
                    url: res.data.fileUrl,
                    // poster: res.data.videoCoverUrl,
                    videoInit: true,
                    fluid: true,
                    autoplay: false,
                    controls: true
                  })
                } else if (
                  res.data.previewStatus == 2 ||
                  res.data.previewStatus == 0
                ) {
                  fileHtml = `<img src='${vdWait}' alt='image' style='max-width: 100%;border-radius: 8px;margin:80px 0 20px' /><p style='color:#999'>视频文件解析转码处理中,请稍后查看.</p>`
                } else if (res.data.previewStatus == 3) {
                  fileHtml = `<img src='${vdFail}' alt='image' style='max-width: 100%;border-radius: 8px;margin:80px 0 20px' /><p style='color:#999'>视频文件解析失败.</p>`
                }
                break
              case 3:
                fileHtml = `<img src='${res.data.fileUrl}' alt='image' style='max-width: 80%;border-radius: 8px;' />`
                break
              case 5:
                // https://view.xdocin.com/view?src=
                fileHtml = `<iframe id='iframe1' width='100%' height='720' style="overflow-y:scroll" scrolling="yes" frameborder='no' border='0' marginwidth='0' marginheight='0' scrolling='no' allowtransparency='yes'
                src='https://doc.15s.com.cn/preview/onlinePreview?url=${encodeURIComponent(
                  encode(res.data.fileUrl)
                )}'></iframe>`
                break
            }
            res.data.fileHtml = fileHtml
            res.data.tagIds = res.data?.tagsBusResList.length > 0 ? true : false
            if (res.data.tagIds) {
              const isAuthor =
                user.userId == res.data.uploadUserId ? true : false
              res.data.createUser = isAuthor
            }
            state.vdTags = res.data.tagsBusResList
            // console.log(state.vdTags)
            state.fileObj = res.data
            // if (res.data.recordFlag == 1) {
            // 记录文件浏览记录
            // }
            queryRecordData(1)
            queryCollect()
            if (res.data.fileType == 2) {
              queryTopic()
            }
            if (vdPlayer) {
              vdPlayer.once('ended', function () {
                console.log(vdPlayer.currentTime)
                console.log(vdPlayer.duration)
                state.playerTime = vdPlayer.currentTime
              })
            }
          } else if (res.code == 301) {
            message.info(res.msg).then(() => {
              router.back()
            })
          } else {
            Modal.info({
              title: '提示',
              content: res.msg,
              onOk() {
                router.back()
              }
            })
          }
          return res.code
        })
        .then((code) => {
          if (code == 200) {
            queryCOmment()
          }
        })
        .catch((err) => {
          message.error(err)
        })
    })

    //
    onUnmounted(() => {
      if (state.fileObj.fileType == 2 && state.fileObj.previewStatus == 1) {
        if (state.playerTime !== vdPlayer.duration) {
          state.playerTime = vdPlayer.currentTime
        }
        if (state.fileObj.id) {
          recordVisit({
            businessId: state.fileObj.id,
            businessType: 3,
            playbackDuration: state.playerTime
          })
        }
      }
    })

    const changeRecord = () => {
      // console.log(state.activeKey)
      state.activeKey = state.activeKey == '1' ? '2' : '1'
    }

    const nextPage = (page) => {
      if (state.activeKey == '1') {
        queryCOmment(page)
      } else if (state.activeKey == '2') {
        queryRecordData(page)
      }
    }

    // 点赞收藏
    const onCollectFile = async (t) => {
      const updateDate = moment().format('YYYY-MM-DD HH:mm:ss')
      let collectData = {
        businessType: businessType,
        businessId: id,
        updateTime: updateDate,
        updateUser: user.userId,
        isLoading: true
      }
      if (t == 1) {
        collectData.likeStatus = state.fileObj.likeStatus == 1 ? 0 : 1 // 1 点赞 2 取消
      } else {
        collectData.collectStatus = state.fileObj.collectStatus == 1 ? 0 : 1 // 1 收藏 2 取消
      }
      const res = await collectLikes(collectData)
      if (res.code == 200) {
        queryCollect()
      }
    }

    // 删除文件
    const delResFile = async () => {
      const res = await deleteFile(id)
      if (res.code == 200) {
        state.fileObj = {}
        message.info('删除成功').then(router.back())
      }
    }

    const sendText = _.debounce(
      async () => {
        if (state.commentText.length == 0) {
          message.info('请输入评论内容')
          return
        }
        const updateDate = moment().format('YYYY-MM-DD HH:mm:ss')
        const cmRes = await postComment({
          businessType: businessType,
          businessId: id,
          commentTime: updateDate,
          content: state.commentText,
          nickName: user.nickName,
          userName: user.userName,
          userId: user.userId
        })
        state.commentText = ''
        if (cmRes.code == 200) {
          queryCOmment()
        }
      },
      1000,
      {
        leading: true,
        trailing: false
      }
    )
    // show话题栏
    const showTopicModal = () => {
      state.showTopic = true
    }
    const hideTopicModal = () => {
      state.showTopic = false
    }
    // 添加热门话题
    const addTag = (tag) => {
      // console.log(tag)
      if (state.vdTags.length == 5) {
        message.info('每个视频最多可关联5个话题')
        return
      }
      const tagStr = JSON.stringify(state.vdTags)
      if (tagStr.indexOf(tag.tagName) !== -1) {
        message.info('当前话题已添加,不能添加重复话题')
        return
      }
      tag.tagId = tag.id
      state.vdTags.push(tag)
    }

    // 添加热门话题
    const removeTag = (i) => {
      state.vdTags.splice(i, 1)
    }

    // 保存话题
    const saveTopic = () => {
      const tagIds = state.vdTags.map((tag) => tag.tagId)
      eidtFileTags(tagIds)
      hideTopicModal()
    }

    // 搜索标签
    const onSearchTags = async (value) => {
      const params = { pageNum: 1, pageSize: 20, tagName: value }
      if (!value) {
        params.orderType = 3
      }
      const res = await getVideoTags(params)
      if (res.code == 200) {
        if (res.rows.length == 0) {
          message.info('暂无相关话题标签')
          return
        }
        state.hotTags = res.rows
      }
    }

    return {
      ...toRefs(state),
      user,
      changeRecord,
      onCollectFile,
      sendText,
      nextPage,
      richRef,
      moment,
      addTag,
      mainBox,
      removeTag,
      saveTopic,
      onSearchTags,
      showTopicModal,
      hideTopicModal,
      delResFile
    }
  }
})
</script>

<style lang="scss" scoped>
.main-box {
  padding: 0;
  min-height: 85vh;
  background-color: transparent;
  :deep(.ant-modal) {
    .head {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      padding: 12px 0;
    }
    .hot-tag-list {
      display: flex;
      flex-wrap: wrap;
      .tag {
        display: inline-block;
        padding: 8px 12px;
        font-size: 12px;
        color: #999999;
        margin-right: 16px;
        margin-bottom: 12px;
        background-color: #f9f9f9;
        border: 1px solid #f9f9f9;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        .i-close {
          background-color: #cccccc;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          color: #fff;
          position: absolute;
          right: -10px;
          top: -10px;
        }
        .i-close:hover {
          background-color: #c0c0c0;
        }
      }
      .tag:hover {
        color: #3e6cec;
        border-color: #3e66ec;
      }
      .tag.active {
        color: #3e6cec;
        border-color: #3e66ec;
      }
    }
  }
  .top-head {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    height: 32px;
    line-height: 32px;
    margin-bottom: 12px;
    .icon-tag {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      cursor: pointer;
    }
    .icon-item {
      color: #999;
      margin-left: 4px;
      cursor: pointer;
    }
    .icon-mini {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      vertical-align: sub;
    }
  }
  .top-count {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
  }

  .gutter-box {
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    min-height: 85vh;

    .pages {
      text-align: right;
      margin-top: 10px;
      :deep(.ant-pagination-simple) {
        .ant-pagination-simple-pager input {
          width: 20px;
          padding: 0;
          margin: 0;
          background-color: transparent;
          border: none;
          border-radius: 0;
        }
      }
    }
    .post-text {
      position: relative;
      margin: 20px 0;
      padding: 6px 0px 44px;
      background-color: #f9f9f9;
      border-radius: 4px;
      .ant-btn {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
      textarea.ant-input {
        min-height: 100px;
        border: none;
        resize: none;
        border-radius: 0px;
        background-color: transparent;
      }
      textarea.ant-input:focus,
      textarea.ant-input:hover {
        border: none;
        box-shadow: none;
        outline: none;
        border-color: transparent;
      }
    }

    .student-list.text-box {
      // overflow-y: scroll;
      .title {
        text-align: center;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }
    .student-list {
      border-radius: 2px;
      height: 100%;
      :deep(.ant-tabs) {
        .ant-tabs-nav {
          display: none;
        }
      }
      .ant-list {
        .ant-list-item {
          padding: 0 0 7px 0;
          border-bottom: none;
          font-size: 14px;
          color: #333;
          .rating-item {
            display: block;
            width: 100%;
            .rating-text {
              font-size: 14px;
              color: #666;
            }
            .user-box {
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .user-info {
                color: #999;
                .icon-avatar {
                  width: 20px;
                  height: 20px;
                  vertical-align: sub;
                  margin-right: 4px;
                  border-radius: 50%;
                }
              }
              .icon-plus {
                margin-left: 12px;
                color: #999;
              }
            }
          }
        }
      }

      .rich-text {
        font-size: 16px;
        line-height: 22px;
        color: #666;
        text-align: center;
        p {
          margin-bottom: 10px;
        }
        .file-box {
          max-width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
