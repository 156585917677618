// 随机字符文件名
export const ramdomString = (length) => {
  let str = ''
  let timestamp = Date.parse(new Date())
  timestamp = timestamp / 1000
  const token = localStorage.getItem('token')
  let yinzi = timestamp + token

  let arr = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  // 随机产生
  for (let i = 0; i < length; i++) {
    let pos = Math.round(Math.random(yinzi) * (arr.length - 1))
    str += arr[pos]
  }
  return str
}

// 转base64 字符串
export const encode = (str) => {
  // 对字符串进行编码
  const encode = encodeURI(str)
  // 对编码的字符串转化base64
  const base64 = btoa(encode)

  return base64
}
// 解密base64 字符串
export const decode = (base64) => {
  // 对base64转编码
  var decode = window.atob(base64)
  // 编码转字符串
  var str = decodeURI(decode)

  return str
}