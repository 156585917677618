import request from '../utils/request/baseAxios'

// 组织架构列表
export const queryUserInfo = () => {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 编辑用户信息
export const editUserInfo = (data) => {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data
  })
}

// 重置密码
export const resetUserPass = (params) => {
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params
  })
}
// 上传头像
export const uploadHeadImg = (data) => {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data
  })
}

// 收藏的资源列表
export const queryCollectRes = (params) => {
  return request({
    url: '/user/collections/list',
    method: 'get',
    params
  })
}

// 收藏的资源列表
export const queryResList = (params) => {
  return request({
    url: '/system/rc/list',
    method: 'get',
    params
  })
}

// 最近访问的资源列表
export const queryResVisit = (params) => {
  return request({
    url: '/user/visit/recentList',
    method: 'get',
    params
  })
}
// 资源库文件夹
export const queryResFolder = (id) => {
  return request({
    url: '/system/rc/treeselect/' + id,
    method: 'get'
  })
}

// 资源库文件列表
export const queryFileList = (params) => {
  return request({
    url: '/user/file/list',
    method: 'get',
    params
  })
}

// 查询账户列表
export const queryUserList = (params) => {
  return request({
    url: '/system/user/list',
    method: 'get',
    params
  })
}

// 发送消息
export const sendMsg = () => {
  return request({
    url: '/user/msgs/send',
    method: 'post'
  })
}

// 消息详情
export const queryMsgInfo = (params) => {
  return request({
    url: '/user/msgs/' + params.id,
    method: 'get'
  })
}

// 站内信
export const queryMyMsg = (params) => {
  return request({
    url: '/user/receiver/list',
    method: 'get',
    params
  })
}

// 站内系统消息
export const querySysMsg = (params) => {
  return request({
    url: '/user/notice/receiver/list',
    method: 'get',
    params
  })
}
// 系统消息接收方的详情
export const querySysDetail = (id) => {
  return request({
    url: '/user/notice/receiver/' + id,
    method: 'get'
  })
}
// 站内信接收方的详情
export const querySiteDetail = (id) => {
  return request({
    url: '/user/receiver/' + id,
    method: 'get'
  })
}

// 系统消息 全部已读
export const setAllSysRead = () => {
  return request({
    url: '/user/notice/receiver/allRead',
    method: 'put'
  })
}

// 系统消息 全部已读
export const setAllSiteRead = () => {
  return request({
    url: '/user/receiver/allRead',
    method: 'put'
  })
}
// 删除站内信
export const delSiteMsg = (id) => {
  return request({
    url: '/user/receiver/' + id,
    method: 'DELETE'
  })
}
// 删除系统消息
export const delSystemMsg = (id) => {
  return request({
    url: '/user/notice/receiver/' + id,
    method: 'DELETE'
  })
}
//发送消息
export const sendMessage = (data) => {
  return request({
    url: '/user/msgs/send',
    method: 'POST',
    data
  })
}

//获取oss policy
export const getOssInfo = (data) => {
  return request({
    url: '/vod/video/getPoicy',
    method: 'POST',
    data
  })
}

//获取oss stsToken
export const getOssSts = (data) => {
  return request({
    url: '/vod/video/getSTS',
    method: 'POST',
    data
  })
}

// 上传资源文件 单个
export const uploadSingleFile = (data) => {
  return request({
    url: '/user/file/uploadSingleFile',
    method: 'POST',
    data
  })
}
// 通知用户资源更新
export const uploadResFile = (data) => {
  return request({
    url: '/user/file',
    method: 'POST',
    data
  })
}

// 通知用户资源更新
export const broadcastResUser = (data) => {
  return request({
    url: '/system/notice/send',
    method: 'POST',
    data
  })
}
// 更新文件夹文件数量
export const updateFolder = (id) => {
  return request({
    url: '/system/rc/refreshRcNum/' + id,
    method: 'get',
  })
}

// （点赞、关注、收藏(或取消)）
export const collectLikes = (data) => {
  return request({
    url: '/user/collections/collectlikes',
    method: 'POST',
    data
  })
}
// 文件详情
export const queryFileDetail = (id) => {
  return request({
    url: '/user/file/' + id,
    method: 'get'
  })
}
// 编辑文件详情
export const editFileDetail = (data) => {
  return request({
    url: '/user/file/',
    method: 'put',
    data
  })
}
// 删除文件
export const deleteFile = (id) => {
  return request({
    url: '/user/file/' + id,
    method: 'DELETE'
  })
}

// 文件详情
export const queryCollectStatus = (params) => {
  return request({
    url: '/user/collections/getInfoByBus',
    method: 'get',
    params
  })
}
// 3.资源库-文件内容浏览记录列表接口
export const recordFileList = (params) => {
  return request({
    url: '/user/visit/list',
    method: 'get',
    params
  })
}
// 4.资源库-文件内容评论记录列表接口
export const commentFileList = (params) => {
  return request({
    url: '/user/talk/list',
    method: 'get',
    params
  })
}
// 发布评论
export const postComment = (data) => {
  return request({
    url: '/user/talk',
    method: 'post',
    data
  })
}
// 浏览记录
export const queryRecord = (params) => {
  return request({
    url: '/user/visit/list',
    method: 'get',
    params
  })
}

// 资源库接口
export const recordVisit = (data) => {
  return request({
    url: '/user/visit/recordVisit',
    method: 'post',
    data
  })
}

//BBS
export const getPlateList = (params) => {
  return request({
    url: '/user/post/list',
    method: 'get',
    params
  })
}

export const getPlateTree = (params) => {
  return request({
    url: '/user/plate/fpTree',
    method: 'get',
    params
  })
}
// 版块详情
export const getPlateDetail = (id) => {
  return request({
    url: `/user/plate/${id}`,
    method: 'get'
  })
}

// 帖子详情
export const getBBsDetail = (id) => {
  return request({
    url: `/user/post/${id}`,
    method: 'get'
  })
}

// 帖子详情
export const postBBs = (data) => {
  return request({
    url: `/user/post`,
    method: 'post',
    data
  })
}

//跟帖列表
export const queryFloorList = (params) => {
  return request({
    url: `/user/floor/list`,
    method: 'get',
    params
  })
}


// 删除帖子
export const delBBsData = (id) => {
  return request({
    url: `/user/post/` + id,
    method: 'delete',
  })
}

// 删除楼层
export const delFloorData = (id) => {
  return request({
    url: `/user/floor/` + id,
    method: 'delete',
  })
}

// 发布跟帖子
export const postFloorData = (data) => {
  return request({
    url: `/user/floor`,
    method: 'post',
    data
  })
}

// 评论楼层
export const replyFloorData = (data) => {
  return request({
    url: `/user/reply`,
    method: 'post',
    data
  })
}

// /user/reply/list
export const getReplyList = (params) => {
  return request({
    url: `/user/reply/list`,
    method: 'get',
    params
  })
}