import axios from 'axios'
import Qs from 'qs'
import { message, Modal } from 'ant-design-vue'
// import { useRouter } from 'vue-router'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const service = axios.create({
  baseURL: '/rc',
  headers: { 'Content-Type': 'application/json' },
  timeout: 30000
})
message.config({
  top: `320px`,
  duration: 1.5,
  maxCount: 3,
  rtl: true
})
let isWaring = false

service.interceptors.request.use(
  (config) => {
    const dataStr = Qs.stringify(config.data)
    if (dataStr.indexOf('isLoading') == -1) {
      message.destroy()
      message.loading('加载中', 0)
    }
    const token = localStorage.getItem('token') || ''
    if (token && config.url !== '/login') {
      config.headers['Authorization'] = token
    }
    if (config.method == 'get') {
      config.data = Qs.stringify(config.data, { arrayFormat: 'indices' })
      config.params = {
        _t: Date.parse(new Date()) / 1000, //让每个请求都携带一个不同的时间参数，防止浏览器缓存不发送请求
        ...config.params
      }
    }
    return config
  },
  (error) => {
    message.destroy()
    // Do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    message.destroy()
    if (response.status == 200) {
      const statusArr = [401, 404]
      if (statusArr.includes(response.data.code)) {
        if (isWaring) {
          return
        }
        isWaring = true
        Modal.info({
          title: '提示',
          centered: true,
          content: '登陆已过期，请重新登录.',//response.data.msg
          onOk() {
            isWaring = false
            localStorage.clear()
            // const router = useRouter()
            // router.push('/login')
            const curUrl = window.location.href
            const url = curUrl.split('#')[0]
            window.location.href = url + '#/login'
          }
        })
        return { code: '', msg: '' }
      }
      if (response?.data.code > 301) {
        // message.info(response.data.msg)
        Modal.info({
          title: '提示',
          centered: true,
          content: response.data.msg
        })
      }
      return response.data
    } else {
      Modal.info({
        title: '提示',
        content: response.data.msg
      })
      // message.warning(response.data.msg)
      Promise.reject()
    }
  },
  (error) => {
    message.destroy()
    console.log("************* error *************")
    console.log(error)
    message.warning('请求服务器异常')
    return Promise.reject()
  }
)

export default service
