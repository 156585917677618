<template>
  <div class="post-box" ref="postBox">
    <div class="page-head">
      <div
        class="left-box"
        :class="{ active: followStatus }"
        @click="onCollectBBs"
      >
        <img
          class="icon-col"
          :src="followStatus ? collectIconOn : collectIcon"
          alt="icon_collect"
        />
        <span>{{ followStatus ? '已关注' : '关注帖子' }}</span>
      </div>
      <div class="right-box">
        <a-button @click="delFloor" v-if="isOwner">删除帖子</a-button>
        <a-button :class="{ active: isPostOwner }" @click="lookUp"
          >只看楼主</a-button
        >
        <img
          v-show="sortFlag == 0"
          @click="sortFloors"
          class="icon-m"
          src="../../../assets/client/icon_sort.png"
          alt="icon_sort"
        />
        <img
          v-show="sortFlag == 1"
          @click="sortFloors"
          class="icon-m"
          src="../../../assets/client/icon_sort_down.png"
          alt="icon_sort"
        />
      </div>
    </div>
    <div class="main-box">
      <div class="block-item" v-for="(item, index) in floorList" :key="item.id">
        <div class="user-info">
          <img
            class="img-b"
            v-if="item.ownerAvatar"
            :src="baseHost + item.ownerAvatar"
            alt="desc"
          />
          <div class="nickname">{{ item.ownerNickName }}</div>
          <div class="bbs-info">
            <div class="counts">
              <img
                class="icon-a"
                src="../../../assets/client/icon_post.png"
                alt=""
              />
              <span> {{ item.ownerPostCount || 0 }}</span>
            </div>
            <div class="counts">
              <img
                class="icon-a"
                src="../../../assets/client/icon_comment.png"
                alt=""
              />
              <span> {{ item.ownerReplyCount || 0 }}</span>
            </div>
          </div>
        </div>
        <div class="block-info">
          <div
            class="desc"
            @click="showImg"
            :class="item.status == 1 ? 'preview' : ''"
            v-html="item.content"
          ></div>
          <div class="lookAll" v-if="item.status == 1">
            <span class="point">...............</span>
            <span @click="lookAll(item)">查看全部</span>
          </div>
          <div class="times">
            <span class="floor-num">{{ item.floorNo }}楼</span>
            <span>{{ item.createTime }}</span>
            <div
              class="toggle-reply"
              @click="expandRep(item)"
              v-if="item.forumFloorReplyList.length > 0"
            >
              <span class="text">{{ item.delFlag ? '展开' : '收起' }}</span>
              <double-right-outlined :class="item.delFlag ? '' : 'down'" />
            </div>
            <span class="text r-back" @click="showEditorModal(2, item)"
              >回复
              <span v-if="item.count > 0">({{ item.count }})</span>
            </span>
          </div>
          <div
            class="reply-list"
            :class="{
              hide: item.delFlag
            }"
            v-if="item.forumFloorReplyList.length > 0"
          >
            <div
              class="rep-item"
              v-for="repItem in item.forumFloorReplyList.slice(
                0,
                item.searchValue
              )"
              :key="repItem.id"
            >
              <div class="user">
                <img
                  v-if="repItem.ownerAvatar"
                  class="icon-a"
                  :src="baseHost + repItem.ownerAvatar"
                  alt=""
                />
                <span class="nickname">{{ repItem.ownerNickName }}: </span>
                <span
                  class="nickname r-back"
                  v-if="repItem.secondReplyerNickName"
                  >回复 {{ repItem.secondReplyerNickName }}:
                </span>
              </div>
              <div class="content" v-text="repItem.content"></div>
              <div class="times">
                <span>{{ repItem.replyTime }}</span>
                <div class="toggle-reply">
                  <span class="text r-back" @click="showEditorModal(2, repItem)"
                    >回复</span
                  >
                </div>
              </div>
            </div>
            <div class="lookAll" v-if="item.searchValue == 5">
              <span></span>
              <span @click="lookRepAll(index, item)">查看更多</span>
            </div>
            <div
              class="pagintions"
              v-if="item.searchValue != 5 && item.count > 10"
            >
              <a-pagination
                size="small"
                :pageSize="10"
                :total="item.count"
                @change="nextReplys"
              >
                <!-- <template #itemRender="{ page, type, originalElement }">
                  <a v-if="type === 'prev'" @click="nextReplys(page)"
                    >上一页
                  </a>
                  <a v-else-if="type === 'next'" @click="nextReplys(page)"
                    >下一页
                  </a>
                  <component :is="originalElement" v-else></component>
                </template> -->
              </a-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pages" v-if="pagination.total > pagination.pageSize">
      <a-pagination
        :current="pagination.current"
        show-quick-jumper
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @change="nextPage"
      />
    </div>

    <div class="post-card" @click="showEditorModal(1)">
      <img
        class="icon-edit"
        src="../../../assets/client/icon_edit.png"
        alt="icon_edit"
      />
      <span>写下你的想法</span>
    </div>
    <a-image
      :width="200"
      :style="{ display: 'none' }"
      :preview="{
        visible: isShowImg,
        onVisibleChange: showPreview
      }"
      :src="previewUrl"
    />
    <a-modal
      :maskClosable="false"
      :getContainer="postBox"
      v-model:visible="showComment"
      centered
      width="48%"
      :title="callBackTitle"
      cancelText="取消"
      okText="回复"
      @cancel="hideEditorModal(2)"
      @ok="postReplyFloor"
    >
      <div>
        <a-textarea
          :rows="16"
          maxLength="300"
          placeholder="最多可输入300个字符."
          v-model:value="commentText"
        ></a-textarea>
      </div>
    </a-modal>
    <a-modal
      :maskClosable="false"
      :getContainer="postBox"
      v-model:visible="showEditor"
      centered
      width="50%"
      title="跟帖"
      cancelText="取消"
      okText="发布"
      @cancel="hideEditorModal(1)"
      @ok="postNewFloor"
    >
      <div style="border: 1px solid #ccc">
        <a-spin size="large" tip="上传中." :spinning="isUpload">
          <Toolbar
            style="border-bottom: 1px solid #ccc; z-index: 999"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 420px"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
          />
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  ref,
  shallowRef,
  toRefs,
  reactive,
  onMounted,
  onBeforeUnmount
} from 'vue'
import { DoubleRightOutlined } from '@ant-design/icons-vue'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import {
  getOssInfo,
  queryFloorList,
  delBBsData,
  postFloorData,
  replyFloorData,
  broadcastResUser,
  collectLikes,
  getBBsDetail,
  getReplyList
} from '../../../api/userApi'
import collectIcon from '../../../assets/client/icon_collect.png'
import collectIconOn from '../../../assets/client/icon_collect_on.png'
import base64 from '../../../utils/base64'
import { ramdomString } from '../../../utils/tools'
import { message, Modal } from 'ant-design-vue'
export default {
  components: {
    DoubleRightOutlined,
    Editor,
    Toolbar
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = Number(route.query.id)
    const postBox = ref()
    const state = reactive({
      token: '',
      callBackTitle: '回复:',
      showEditor: false,
      showComment: false,
      isUpload: false,
      isShowImg: false, // 预览图片
      previewUrl: '', //预览地址
      commentText: '',
      isPostOwner: 0, // 只看楼主
      followStatus: 0, // 关注状态
      sortFlag: 0, // 排序记录
      plateObj: {
        createTime: '',
        desc: '',
        headDiagram: '',
        name: ''
      },
      curIndex: '', // 当前跟帖
      floorList: [], // 楼层帖子list
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      isOwner: false,
      repPagintion: {
        pageNum: 1,
        pageSize: 5
      },
      replyUser: {
        postId: '',
        floorId: '',
        ownerId: '',
        nickName: '',
        replyType: 0
      },
      baseHost: location.origin + '/rc'
    })

    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const toolbarConfig = {
      // toolbarKeys: []
      insertKeys: {
        index: 22, // 插入的位置，基于当前的 toolbarKeys
        keys: ['uploadVideo']
      },
      excludeKeys: [
        'italic',
        'bgColor',
        'insertLink',
        'bulletedList',
        'divider',
        'table',
        'emotion',
        'codeBlock',
        'numberedList',
        'headerSelect',
        'insertTable',
        'insertVideo',
        'fullScreen',
        'insertImage' // 排除菜单组，写菜单组 key 的值即可
      ]
    }
    const editorConfig = {
      MENU_CONF: {},
      placeholder: '请输入内容...'
    }
    //  内容 HTML
    const valueHtml = ref('')

    const getFloors = async (params) => {
      const res = await queryFloorList(params)
      const permissions = localStorage.getItem('permissions') || ''
      const isDel = permissions.indexOf('system.post.removeAll') > -1
      if (res.code == 200) {
        res.rows.forEach((item) => {
          // console.log(decodeContent)
          // newContent = newContent.replace(/%/g, '%25');
          let decodeContent = base64.decode(item.content)
          decodeContent = decodeURIComponent(decodeContent)
          // console.log(decodeContent)
          item.content = decodeContent
          const lineSum = decodeContent.match(/\/p/gim).length //正则统计 p标签出现次数
          item.status = lineSum > 18 ? 1 : 0
          item.searchValue = item.count > 5 ? 5 : item.count
        })
        state.isOwner = user.userId === res.rows[0].owner || isDel
        state.floorList = res.rows || []
        state.pagination.total = res.total || 0
      }
    }

    onMounted(() => {
      getFloors({
        postId: id,
        pageNum: state.pagination.current,
        pageSize: state.pagination.pageSize
      })
      getBBsDetail(id).then((res) => {
        if (res.code == 200) {
          state.followStatus = res.data.followStatus
        }
      })
    })

    editorConfig.MENU_CONF['uploadImage'] = {
      fieldName: 'file',
      maxNumberOfFiles: 9,
      // 自定义上传
      async customUpload(file, insertFn) {
        // file 即选中的文件
        const ossResult = await getOssInfo({
          businessType: 6
        })
        if (ossResult.code !== 200) {
          message.error('oss签名信息获取失败,无法上传图片.')
          return
        }
        let fileName = file.name
        const fileType = fileName
          .substring(fileName.length - 6, fileName.length)
          .split('.')[1]
          .toLowerCase() // 文件后缀
        const file_store_name = ramdomString(32)
        const ossKey = ossResult.data.dir + file_store_name + '.' + fileType
        // 自己实现上传，并得到图片 url alt href
        const formData = new FormData()
        formData.append('OSSAccessKeyId', ossResult.data.ossAccessKeyId)
        formData.append('fileName', file_store_name)
        formData.append('signature', ossResult.data.signature)
        formData.append('policy', ossResult.data.policy)
        formData.append('key', ossKey) //注意顺序，file要在key的后面。不然会返回找不到key 的错误
        formData.append('file', file)
        formData.append('success_action_status', 200)
        state.isUpload = true
        const res = await axios.post(ossResult.data.host, formData)
        state.isUpload = false
        if (res.status == 200) {
          const imgUrl = ossResult.data.host + '/' + ossKey
          // 最后插入图片
          insertFn(imgUrl, '')
        } else {
          alert('上传图片失败')
        }
      }
    }

    editorConfig.MENU_CONF['uploadVideo'] = {
      // 自定义上传
      async customUpload(file, insertFn) {
        const ossResult = await getOssInfo({
          businessType: 6
        })
        if (ossResult.code !== 200) {
          message.error('oss签名信息获取失败,无法上传视频.')
          return
        }
        let fileName = file.name
        const fileType = fileName
          .substring(fileName.length - 6, fileName.length)
          .split('.')[1]
          .toLowerCase() // 文件后缀
        const file_store_name = ramdomString(32)
        const ossKey = ossResult.data.dir + file_store_name + '.' + fileType
        // 自己实现上传，并得到图片 url alt href
        const formData = new FormData()
        formData.append('OSSAccessKeyId', ossResult.data.ossAccessKeyId)
        formData.append('fileName', file_store_name)
        formData.append('signature', ossResult.data.signature)
        formData.append('policy', ossResult.data.policy)
        formData.append('key', ossKey) //注意顺序，file要在key的后面。不然会返回找不到key 的错误
        formData.append('file', file)
        formData.append('success_action_status', 200)
        state.isUpload = true
        const res = await axios.post(ossResult.data.host, formData)
        state.isUpload = false
        if (res.status == 200) {
          const videoUrl = ossResult.data.host + '/' + ossKey
          // 最后插入视频
          insertFn(videoUrl, '')
        } else {
          alert('上传图片失败')
        }
        // 从 res 中找到 url ，然后插入视频
      }
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) {
        return
      }
      editor.destroy()
    })
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      editor.isFocused() ? editor.focus() : ''
    }
    const showEditorModal = (type, curItem) => {
      switch (type) {
        case 1:
          state.showEditor = true
          if (editorRef.value) {
            editorRef.value.focus()
          }
          break
        case 2:
          // console.log(curItem)
          state.callBackTitle = '回复 ' + curItem.ownerNickName + ': '
          state.replyUser = {
            floorId: curItem.floorId ? curItem.floorId : curItem.id, // 楼层id
            ownerId: curItem.owner,
            nickName: curItem.ownerNickName,
            replyType: curItem.floorId ? 1 : 0
          }
          state.showComment = true
          break
      }
    }
    const hideEditorModal = (type) => {
      switch (type) {
        case 1:
          state.showEditor = false
          break
        case 2:
          state.showComment = false
          break
      }
    }

    // 展开回复
    const expandRep = (item) => {
      item.delFlag = item.delFlag == 0 ? 1 : 0
    }
    const lookAll = (item) => {
      item.status = item.status == 0 ? 1 : 0
    }

    const lookRepAll = async (index, item) => {
      state.curIndex = index
      const res = await getReplyList({
        floorId: item.id,
        pageNum: 2,
        pageSize: 5
      })
      if (res.code == 200) {
        if (res.total > 0) {
          item.searchValue = res.total
          item.forumFloorReplyList = [...item.forumFloorReplyList, ...res.rows]
        }
      }
    }

    //删除帖子
    const delFloor = () => {
      Modal.confirm({
        width: 480,
        centered: true,
        title: '提示',
        okText: '确认',
        cancelText: '取消',
        content: '确认要删除该帖子吗?',
        onOk() {
          delBBsData(id).then((res) => {
            if (res?.code == 200) {
              message.info('帖子已删除').then(() => {
                router.back()
              })
            }
          })
        }
      })
    }

    const nextPage = (page, pageSize) => {
      state.pagination.current = page
      state.pagination.pageSize = pageSize
      getFloors({
        postId: id,
        pageNum: page,
        pageSize: pageSize
      })
    }

    // 只看楼主
    const lookUp = () => {
      state.isPostOwner = state.isPostOwner ? null : 1
      getFloors({ postId: id, postOwnerFlag: state.isPostOwner })
    }
    // 只看楼主
    const sortFloors = () => {
      state.sortFlag = state.sortFlag ? 0 : 1
      getFloors({ postId: id, orderType: state.sortFlag })
    }

    // 通知用户论坛消息
    const notifyBBsMsg = async (noticeType, content, backUserId) => {
      const sendUserData = {
        noticeTitle: '', //标题
        noticeContent: content, // 公告内容
        noticeType: noticeType, //1通知 2公告 3资源更新 4跟帖或评论
        noticeBsRelationReqs: [
          {
            businessId: id,
            businessType: noticeType == 4 ? 6 : 7
          }
        ], // 帖子id
        receiverIds: [backUserId], // 通知用户组
        sendUser: user.userId, //发送人id
        sendNickName: user.nickName, //发送人昵称
        sendUserName: user.userName, //发送人账户
        status: 0 // 0正常 1关闭
      }
      await broadcastResUser(sendUserData)
    }

    // 发布跟帖
    const postNewFloor = async () => {
      let contentUrl = editorRef.value.getHtml()
      contentUrl = contentUrl.replace(
        /style=""/g,
        '<img style="max-width:100%" '
      )
      if (contentUrl.indexOf('<video') > -1) {
        contentUrl = contentUrl.replace(
          /<video /g,
          '<video controlslist="nodownload" '
        )
      }
      contentUrl = encodeURI(contentUrl)
      contentUrl = base64.encode(contentUrl)
      const user = JSON.parse(localStorage.getItem('user') || '{}')
      const sendTime = new Date().getTime()
      const res = await postFloorData({
        postId: id,
        content: contentUrl,
        replyTime: sendTime, // 跟帖時間
        createBy: user.nickName,
        createUser: user.userId,
        createTime: sendTime
      })
      if (res.code == 200) {
        editorRef.value.setHtml('')
        valueHtml.value = ''
        message.success('发布成功').then(async () => {
          await notifyBBsMsg(4, contentUrl, Number(state.floorList[0].owner))
          await getFloors({
            postId: id,
            pageNum: state.pagination.current,
            pageSize: state.pagination.pageSize
          })
        })
      }
      hideEditorModal(1)
    }

    const postReplyFloor = async () => {
      if (state.commentText.length == 0) {
        message.info('请输入回复内容')
        return
      }

      const sendTime = new Date().getTime()
      const res = await replyFloorData({
        floorId: state.replyUser.floorId,
        content: state.commentText,
        owner: state.replyUser.ownerId, // 楼层或者 被回复人id
        replyType: state.replyUser.replyType, // （0-回复层主 1-回复指定人）
        replyToUser: state.replyUser.replyType ? state.replyUser.ownerId : '', //回复用户
        replyTime: sendTime, // 回复时间
        createBy: user.nickName,
        createUser: user.userId,
        createTime: sendTime
      })
      if (res.code == 200) {
        message.info('')
        await notifyBBsMsg(
          5,
          state.commentText,
          Number(state.replyUser.ownerId)
        )
        await getFloors({
          postId: id,
          pageNum: state.pagination.current,
          pageSize: state.pagination.pageSize
        })
        // })
        state.commentText = ''
      }
      hideEditorModal(2)
    }
    // 收藏
    const onCollectBBs = async () => {
      const updateDate = new Date().getTime()
      let collectData = {
        businessType: 5,
        businessId: id,
        updateTime: updateDate,
        updateUser: user.userId,
        isLoading: true,
        followStatus: state.followStatus == 1 ? 0 : 1 // 1 收藏 2 取消
      }
      const res = await collectLikes(collectData)
      if (res.code == 200) {
        state.followStatus = state.followStatus ? 0 : 1
      }
    }

    const nextReplys = async (page) => {
      const res = await getReplyList({
        floorId: state.floorList[state.curIndex].id,
        pageNum: page,
        pageSize: 10
      })
      if (res.code == 200) {
        // state.floorList[state.curIndex].count = res.total
        state.floorList[state.curIndex].forumFloorReplyList = res.rows
      }
    }

    // 查看图片
    const showImg = (e) => {
      if (e.target.nodeName.toLowerCase() === 'img') {
        state.previewUrl = e.target.src
        showPreview(true)
      }
    }

    // 显示预览图片
    const showPreview = (val) => {
      state.isShowImg = val
    }

    return {
      ...toRefs(state),
      postBox,
      editorRef,
      collectIcon,
      collectIconOn,
      mode: 'simple',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      showEditorModal,
      hideEditorModal,
      expandRep,
      lookAll,
      lookRepAll,
      lookUp,
      delFloor,
      sortFloors,
      postNewFloor,
      postReplyFloor,
      nextPage,
      onCollectBBs,
      nextReplys,
      showPreview,
      showImg
    }
  }
}
</script>

<style lang="scss" scoped>
.post-box {
  :deep(.ant-modal) {
    .ant-modal-title {
      text-align: left;
    }
    textarea.ant-input {
      background-color: #f8f8f8;
    }
  }

  .page-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    .left-box {
      font-size: 14px;
      color: #999;
      cursor: pointer;
      > .icon-col {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        vertical-align: middle;
      }
    }
    .active {
      color: #333;
    }
    .right-box {
      .ant-btn {
        margin-right: 24px;
        background-color: transparent;
        border-radius: 4px;
        color: #999;
      }
      .ant-btn.active {
        color: #3e6cec;
        border-color: #3e6cec;
      }
      .ant-btn:hover {
        color: #3e6cec;
        border-color: #3e6cec;
      }
      .icon-m {
        width: 24px;
        height: 24px;
        vertical-align: sub;
        cursor: pointer;
      }
      > span {
        font-weight: bold;
        font-size: 20px;
        color: #333;
      }
    }
  }
}
.pages {
  margin: 0 0 80px;
  text-align: right;
}
.post-card {
  position: fixed;
  left: 244px;
  right: 32px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  box-shadow: 2px 3px 3px #ddd;
  .icon-edit {
    width: 24px;
    margin-right: 6px;
    vertical-align: middle;
  }
}
.post-card:hover {
  opacity: 0.8;
}
.main-box {
  padding: 24px;
  height: auto;
  margin-bottom: 40px;
  min-height: 80vh;

  .block-item {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    .user-info {
      position: relative;
      width: 156px;
      padding: 0px 21px 0 10px;
      text-align: center;
      .nickname {
        margin: 12px 0 10px;
        font-size: 16px;
        color: #333;
      }
      .bbs-info {
        display: flex;
        justify-content: space-between;
        .counts {
          font-size: 12px;
          color: #ccc;
          .icon-a {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
        }
      }
    }
    .user-info::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 114px;
      background-color: #f2f2f2;
    }

    .img-b {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .block-info {
      width: 100%;
      margin-left: 20px;
      font-size: 16px;
      color: #666;
      .theme-sum {
        margin-left: 40px;
      }
      .desc {
        font-size: 16px;
        color: #333;
        min-height: 92px;
      }
      .preview {
        height: 400px;
        overflow: hidden;
      }
      :deep(.pagintions) {
        padding: 20px 0 5px;
        text-align: right;
        .ant-pagination.mini .ant-pagination-prev,
        .ant-pagination.mini .ant-pagination-next {
          margin: 0 10px;
        }
      }
      .lookAll {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px 0;
        text-align: right;
        color: #3e6cec;
        font-size: 14px;
        .point {
          font-size: 18px;
          color: #999;
        }
      }
      .times {
        margin-top: 5px;
        text-align: right;
        color: #ddd;
        font-size: 14px;
        .floor-num {
          color: #666;
          margin-right: 6px;
        }
        .toggle-reply {
          display: inline-block;
          cursor: pointer;
          color: #ccc;
          font-size: 14px;
          .anticon {
            transition: transform 0.3s;
          }
          .anticon.down {
            transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
          }
        }
        .text {
          margin: 0 2px 0 12px;
        }
        .r-back {
          color: #3e6cec;
          cursor: pointer;
        }
      }
      .reply-list.hide {
        opacity: 0;
        height: 0;
        padding: 0;
        display: none;
      }
      .reply-list {
        margin-top: 10px;
        background-color: #f8f8f8;
        padding: 10px;
        height: auto;
        opacity: 1;
        transition: opacity 0.5s, height 0.5s;
        .rep-item {
          font-size: 14px;
          .user {
            display: inline-flex;
            vertical-align: middle;
            .icon-a {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              vertical-align: middle;
            }
            .nickname {
              color: #333;
              margin-left: 8px;
            }
          }
          .content {
            display: inline-flex;
            margin-left: 16px;
            max-width: 90%;
          }
        }
      }
      .reply-list.default {
        max-height: 276px;
        overflow: hidden;
      }
      .reply-list.all {
        max-height: 556px;
        overflow-y: scroll;
      }

      .tools {
        .icon_m {
          width: 20px;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .tools {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      min-width: 100px;
      margin: 0 10px;
    }
  }
}
</style>
