import { createRouter, createWebHashHistory } from 'vue-router'

import resDetail from '../views/sources/resDetail/resDetail.vue'

import postList from '../views/bbs/postList/postList.vue'
const routes = [
  {
    path: '/',
    redirect: '/layout'
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/myLogin.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/eastLogin',
    name: 'eastLogin',
    component: () =>
      import(/* webpackChunkName: "eastLogin" */ '../views/eastLogin.vue'),
    meta: {
      title: '东方幼儿园'
    }
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/layout/collection',
    component: () =>
      import(/* webpackChunkName: "userInfo" */ '../layout/index.vue'),
    meta: {
      title: '个人信息'
    },
    children: [
      {
        path: '/layout/userinfo', // 我的桌面
        name: 'userInfo',
        meta: {
          title: '个人信息'
        },
        component: () =>
          import(
            /* webpackChunkName: "userInfo" */ '../views/welcome/index.vue'
          )
      },
      {
        path: '/layout/password', // 修改密码
        name: 'Password',
        component: () =>
          import(
            /* webpackChunkName: "Password" */ '../views/welcome/password/password.vue'
          ),
        meta: {
          title: '修改密码'
        }
      },
      {
        path: '/layout/sendmsg', // 发送消息
        name: 'SendMsg',
        component: () =>
          import(
            /* webpackChunkName: "SendMsg" */ '../views/welcome/sendMsg/sendmsg.vue'
          ),
        meta: {
          title: '发送消息',
          keepAlive: true //true为需要缓存，false为不需要缓存（不需要的也可以不加）
        }
      },
      {
        path: '/layout/userList', // 收件人列表
        name: 'userList',
        component: () =>
          import(
            /* webpackChunkName: "userList" */ '../views/welcome/userList/userList.vue'
          ),
        meta: {
          title: '收件人列表'
        }
      },
      {
        path: '/layout/contacts', // 集团通讯录
        name: 'Contacts',
        component: () =>
          import(
            /* webpackChunkName: "Contacts" */ '../views/contacts/index.vue'
          ),
        meta: {
          title: '集团通讯录'
        }
      },
      {
        path: '/layout/collection', // 收藏夹
        name: 'Collection',
        component: () =>
          import(
            /* webpackChunkName: "Collection" */ '../views/collections/index.vue'
          ),
        meta: {
          title: '收藏夹'
        }
      },
      {
        path: '/layout/msgDetail', // 收藏夹
        name: 'MsgDetail',
        component: () =>
          import(
            /* webpackChunkName: "MsgDetail" */ '../views/collections/myInfo/msgDetail.vue'
          ),
        meta: {
          title: '收藏夹'
        }
      },
      {
        path: '/layout/myMsg', // 收藏夹
        name: 'myMsg',
        component: () =>
          import(
            /* webpackChunkName: "myMsg" */ '../views/collections/myMsg/myMsg.vue'
          ),
        meta: {
          title: '我的消息'
        }
      },
      {
        path: '/layout/source', // 资源库
        name: 'Source',
        component: () =>
          import(/* webpackChunkName: "Source" */ '../views/sources/index.vue'),
        meta: {
          title: '资源库'
        }
      },
      {
        path: '/layout/fileList', // 组织架构
        name: 'fileList ',
        component: () =>
          import(
            /* webpackChunkName: "fileList" */ '../views/sources/fileList/fileList.vue'
          ),
        meta: {
          title: '资源库文件列表',
          keepAlive: true
        }
      },
      {
        path: '/layout/uploadRes', // 资源库
        name: 'uploadPage',
        component: () =>
          import(
            /* webpackChunkName: "uploadPage" */ '../views/sources/uploadPage/uploadPage.vue'
          ),
        meta: {
          title: '上传资源',
          keepAlive: true
        }
      },
      {
        path: '/layout/resDetail', // 资源详情
        name: 'resDetail',
        component: resDetail,
        meta: {
          title: '资源详情'
        }
      },
      {
        path: '/layout/bbs', // 论坛中心
        name: 'bbs',
        component: () =>
          import(/* webpackChunkName: "bbs" */ '../views/bbs/index.vue'),
        meta: {
          title: '论坛中心'
        }
      },
      {
        path: '/layout/bbsList', // 版块详情
        name: 'bbsList',
        component: () =>
          import(
            /* webpackChunkName: "bbsList" */ '../views/bbs/bbsList/bbsList.vue'
          ),
        meta: {
          title: '版块详情'
        }
      },
      {
        path: '/layout/post', // 发布帖子
        name: 'postbbs',
        component: () =>
          import(
            /* webpackChunkName: "postBBs" */ '../views/bbs/postBBs/postBBs.vue'
          ),
        meta: {
          title: '发布帖子'
        }
      },
      {
        path: '/layout/postList', // 资源库
        name: 'postList',
        component: postList,
        meta: {
          title: '帖子详情'
        }
      },
      // 管理管 routers
      {
        path: '/admin/system', // 组织架构
        name: 'System',
        component: () =>
          import(
            /* webpackChunkName: "System" */ '../vadmin/system/system.vue'
          ),
        meta: {
          title: '组织架构'
        }
      },
      {
        path: '/admin/roles', // 组织架构
        name: 'Roles',
        component: () =>
          import(/* webpackChunkName: "Roles" */ '../vadmin/roles/roles.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/admin/roles/auth', // 组织架构
        name: 'RoleAuth',
        component: () =>
          import(
            /* webpackChunkName: "RoleAuth" */ '../vadmin/roles/rolesAuth/roleAuth.vue'
          ),
        meta: {
          title: '角色权限分配'
        }
      },
      {
        path: '/admin/repository', // 组织架构
        name: 'Repository ',
        component: () =>
          import(
            /* webpackChunkName: "Repository" */ '../vadmin/repository/repository.vue'
          ),
        meta: {
          title: '资源库管理'
        }
      },
      {
        path: '/admin/rangeTree', // 组织架构
        name: 'treeRange',
        component: () =>
          import(
            /* webpackChunkName: "treeRange" */ '../vadmin/repository/treeRange/treeRange.vue'
          ),
        meta: {
          title: '文件夹可见范围'
        }
      },
      {
        path: '/admin/tag', // 视频标签
        name: 'tags',
        component: () =>
          import(
            /* webpackChunkName: "treeRange" */ '../vadmin/vtags/vtags.vue'
          ),
        meta: {
          title: '视频标签管理'
        }
      },
      {
        path: '/admin/forum', // 组织架构
        name: 'forum',
        component: () =>
          import(/* webpackChunkName: "forum" */ '../vadmin/forum/forum.vue'),
        meta: {
          title: '论坛管理'
        }
      },
      // 东方幼儿园
      {
        path: '/vschool/home', // 组织架构
        name: 'home',
        component: () =>
          import(/* webpackChunkName: "home" */ '../vschool/sliderHome/sliderHome.vue'),
        meta: {
          title: '首页轮播管理'
        }
      },
      {
        path: '/vschool/richtext', // 组织架构
        name: 'richtext',
        component: () =>
          import(/* webpackChunkName: "richtext" */ '../vschool/richText/richText.vue'),
        meta: {
          title: '校园巡游'
        }
      },
      {
        path: '/vschool/category', // 组织架构
        name: 'category',
        component: () =>
          import(/* webpackChunkName: "category" */ '../vschool/category/category.vue'),
        meta: {
          title: '素材分类管理'
        }
      },
      {
        path: '/vschool/classify', // 组织架构
        name: 'classify',
        component: () =>
          import(/* webpackChunkName: "classify" */ '../vschool/classify/classify.vue'),
        meta: {
          title: '园庆动态'
        }
      },
      {
        path: '/vschool/avatars', // 头像框管理
        name: 'avatars',
        component: () =>
          import(/* webpackChunkName: "avatars" */ '../vschool/avatars/avatars.vue'),
        meta: {
          title: '头像框管理'
        }
      },
      {
        path: '/vschool/photo', // 组织架构
        name: 'photo',
        component: () =>
          import(/* webpackChunkName: "forum" */ '../vschool/photos/photo.vue'),
        meta: {
          title: '作品管理'
        }
      },
      {
        path: '/vschool/slider', // 组织架构
        name: 'slider',
        component: () =>
          import(/* webpackChunkName: "slider" */ '../vschool/sliderDesc/sliderDesc.vue'),
        meta: {
          title: '寄语轮播图片'
        }
      },
      {
        path: '/vschool/comment', // 寄语管理
        name: 'comment',
        component: () =>
          import(/* webpackChunkName: "forum" */ '../vschool/comment/comment.vue'),
        meta: {
          title: '寄语管理'
        }
      },
      {
        path: '/vschool/material', // 素材管理
        name: 'material',
        component: () =>
          import(/* webpackChunkName: "forum" */ '../vschool/material/material.vue'),
        meta: {
          title: '素材管理'
        }
      }
      // {
      //   path: '/vschool/circle', // 环形条
      //   name: 'circle',
      //   component: () =>
      //     import(/* webpackChunkName: "circle" */ '../vschool/circle/circle.vue'),
      //   meta: {
      //     title: '环形条'
      //   }
      // }
    ]
  }
]

const router = createRouter({
  // process.env.BASE_URL
  history: createWebHashHistory(),
  routes: routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.path);
  const curHost = window.location.host;
  console.log(curHost);
  let defaultLogin = '/login';
  if (curHost.indexOf('df.jmclass.com.cn') !== -1 || curHost.indexOf('test.shbj.com.cn') !== -1) {
    defaultLogin = '/eastLogin'
  }
  if (to.path === defaultLogin) {
    next()
  } else {
    let curPath = localStorage.getItem('defaultPath') || defaultLogin
    const token = localStorage.getItem('token')
    if (token == null || token === '') {
      next(defaultLogin)
    } else if (to.path == '/') {
      next(curPath)
    } else {
      next()
    }
  }
})

export default router
