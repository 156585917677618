import request from '../utils/request/baseAxios'

export const toLogin = (data) => {
  return request({
    url: '/login',
    method: 'POST',
    data: data
  })
}

export const exit = () => {
  return request({
    url: '/logout',
    method: 'get'
  })
}
// 用户信息
export const getAdminInfo = () => {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}
export const getRouters = () => {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}
// 组织架构列表
export const querySystem = () => {
  return request({
    url: '/system/dept/treeselect',
    method: 'get'
  })
}
// 新增组织架构部门
export const addSystemDept = (data) => {
  return request({
    url: '/system/dept',
    method: 'post',
    data: data
  })
}
// 组织架构部门编辑
export const editSystemDept = (data) => {
  return request({
    url: '/system/dept',
    method: 'put',
    data: data
  })
}
// 删除组织架构部门
export const delsystemDept = (id) => {
  return request({
    url: '/system/dept/' + id,
    method: 'delete'
  })
}
// 修改成员密码
export const resetPass = (data) => {
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data
  })
}
//系统菜单
export const getMenuRouter = () => {
  return request({
    url: '/getRouters',
    method: 'get'
  })
}

// 学校用户列表
export const getSchUserList = (params) => {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: params
  })
}

// 学校用户列表
export const getSingleUserList = (params) => {
  return request({
    url: '/system/user/selectUserList',
    method: 'get',
    params: params
  })
}

// 新增成员
export const addSchUser = (data) => {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 编辑成员
export const editSchUser = (data) => {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}
// 编辑成员
export const delSchUser = (id) => {
  return request({
    url: '/system/user/' + id,
    method: 'delete',
  })
}

// 禁用账户
export const disbaleSchUser = (data) => {
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 角色列表
export const getRoleList = (params) => {
  return request({
    url: '/system/role/list',
    method: 'get',
    params
  })
}

// 新增角色
export const addRoleUser = (data) => {
  return request({
    url: '/system/role',
    method: 'post',
    data: data
  })
}

// 编辑角色
export const editRoleUser = (data) => {
  return request({
    url: '/system/role',
    method: 'put',
    data: data
  })
}

// 设置角色权限
export const setRoleAuths = (data) => {
  return request({
    url: '/system/role/authRoleMenus',
    method: 'put',
    data: data
  })
}
// 设置板块权限
export const setPlateAuth = (data) => {
  return request({
    url: '/user/plate/setPlateAuth',
    method: 'put',
    data: data
  })
}
// /user/plate/setPlateAuth

// 删除组织架构部门
export const delRoleUser = (roleId) => {
  return request({
    url: '/system/role/' + roleId,
    method: 'delete'
  })
}
// 禁用角色账户
export const disbaleRoleUser = (data) => {
  return request({
    url: '/system/role/changeStatus',
    method: 'put',
    data
  })
}
// 获取角色权限列表
export const getRoleAuthMenu = (roleId) => {
  return request({
    url: '/system/menu/roleMenuTreeselect/' + roleId,
    method: 'get'
  })
}

// 资源库tree
export const queryResTrees = (params) => {
  return request({
    url: '/system/rc/treeselect',
    method: 'get',
    params
  })
}

// 查看详情
export const queryResDetail = (id) => {
  return request({
    url: '/system/rc/' + id,
    method: 'get'
  })
}

// 编辑资源库文件夹
export const editFolder = (data) => {
  return request({
    url: '/system/rc',
    method: 'put',
    data
  })
}
// 移动资源库文件夹
export const moveFolder = (data) => {
  return request({
    url: '/system/rc/move',
    method: 'put',
    data
  })
}

// 升降级资源库文件夹
export const moveLevelFolder = (data) => {
  return request({
    url: '/system/rc/moveLevel',
    method: 'put',
    data
  })
}

// 资源库管理-新增
export const addRepository = (data) => {
  return request({
    url: '/system/rc',
    method: 'POST',
    data
  })
}
// 资源库管理-编辑
export const editRepository = (data) => {
  return request({
    url: '/system/rc',
    method: 'put',
    data
  })
}

// 资源库文件夹权限列表
export const queryResAuths = (folderId) => {
  return request({
    url: '/system/rc/getResourceAuth/' + folderId,
    method: 'get'
  })
}
// 资源库文件夹权限列表
export const queryPlateAuths = (folderId) => {
  return request({
    url: '/user/plate/getPlateAuth/' + folderId,
    method: 'get'
  })
}
// 资源库文件夹权限列表
export const setResAuths = (data) => {
  return request({
    url: '/system/rc/setResourceAuth',
    method: 'put',
    data
  })
}

// 资源库文件夹权限列表
export const deleteFolder = (data) => {
  return request({
    url: '/system/rc/clean',
    method: 'POST',
    data
  })
}

// 获取用户未读消息
export const getUserReadNum = () => {
  return request({
    url: '/system/user/profile/getMsgNum',
    method: 'get'
  })
}

// 版块列表
export const getPlateData = (params) => {
  return request({
    url: '/user/plate/list',
    method: 'get',
    params
  })
}

// 新增版块
export const addNewBlock = (data) => {
  return request({
    url: '/user/plate',
    method: 'post',
    data
  })
}
// 编辑版块
export const eidtBlock = (data) => {
  return request({
    url: '/user/plate',
    method: 'put',
    data
  })
}
// 编辑版块
export const delBlock = (id) => {
  return request({
    url: '/user/plate/' + id,
    method: 'delete'
  })
}


// 视频标签列表
export const getVideoTags = (params) => {
  return request({
    url: '/user/tags/list',
    method: 'get',
    params
  })
}

// 新增标签
export const addVideoTag = (data) => {
  return request({
    url: '/user/tags',
    method: 'post',
    data: data
  })
}

// 编辑标签
export const editVideoTag = (data) => {
  return request({
    url: '/user/tags/',
    method: 'put',
    data: data
  })
}

// 删除标签
export const delVideoTag = (id) => {
  return request({
    url: '/user/tags/' + id,
    method: 'delete',
  })
}